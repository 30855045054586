import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import {Helmet} from "react-helmet/es/Helmet";

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        {/*<p>&copy; {new Date().getFullYear()} Delog &bull; Crafted with <span role="img" aria-label="love">❤️</span> by <a href="https://w3layouts.com">W3Layouts</a></p>*/}
        <div className="placeholder">
          <p>Maike Sonntag<br />
            <a href="mailto:info@wild-thing-yoga.de">info@wild-thing-yoga.de</a>
          </p>
        </div>
      </footer>
    </div>
  )
}
